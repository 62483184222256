import React from "react"
import Layout, {
  DESKTOP_HEADER_HEIGHT,
  MOBILE_HEADER_HEIGHT,
} from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql } from "gatsby"
import styled from "styled-components"
import FeaturedRecipes from "../components/Recipe/FeaturedRecipes"
import colors from "../styles/colors"
import fonts from "../styles/fonts"
import breakpoints from "../styles/breakpoints"

const Heading = styled.h2`
  ${fonts.workSansBold}
  line-height: 1.104;
  margin: 2rem 0;
  color: ${colors.yellow};
  text-align: center;
`

const PageContainer = styled.div`
  padding-top: calc(4vmax + ${DESKTOP_HEADER_HEIGHT}px);
  padding-left: 5vw;
  padding-right: 5vw;
  padding-bottom: 6.6vmax;

  @media (max-width: ${breakpoints.md}) {
    padding-top: calc(4vmax + ${MOBILE_HEADER_HEIGHT}px);
  }
`

const TagTemplate = (props) => {
  const { metaTitle, metaDescription, socialMediaImage } = props.data.contentfulRecipeTag
  const recipes = props.data.allContentfulRecipePage.edges
  const path = props.location.pathname

  let sharingImage = false

  if (socialMediaImage) {
    sharingImage = socialMediaImage.file.url
  }

  return (
    <Layout bg={colors.royalBlue}>
      <Seo title={metaTitle} description={metaDescription} path={path} image={sharingImage}/>
      <PageContainer>
        <Heading>Featured Recipes</Heading>
        <FeaturedRecipes recipes={recipes} />
      </PageContainer>
    </Layout>
  )
}

export default TagTemplate

export const pageQuery = graphql`
  query TagTemplateQuery($slug: String!) {
    contentfulRecipeTag(shortSlug: { eq: $slug }) {
      id
      metaTitle
      metaDescription
      slug
      socialMediaImage {
        file {
          url
        }
      }
    }
    allContentfulRecipePage {
      edges {
        node {
          id
          image {
            gatsbyImageData
            description
          }
          slug
          category {
            title
            slug
          }
          position
        }
      }
    }
  }
`
